import { React, useEffect, useState } from 'react'
import Footer from '../footer'
import Navbar from '../navbar'
import footprint from "../assets/images/icon/cat_foot_print.svg"
import catmov from "../assets/images/icon/cat_walk_round_shap.svg"
import star from "../assets/img-sspunk/color-star-1.png"
import star2 from "../assets/img-sspunk/color-star-2.png"
import modi from "../assets/img-sspunk/nft-sl-5.jpg"
import blur from "../assets/img-sspunk/blur-1.png"
import nft1 from "../assets/img-sspunk/nft-sl-1.jpg"
import nft2 from "../assets/img-sspunk/nft-sl-2.jpg"
import nft3 from "../assets/img-sspunk/nft-sl-3.jpg"
import nft4 from "../assets/img-sspunk/nft-sl-4.jpg"
import starfloating from "../assets/img-sspunk/star-floating.png"
import mulstar from "../assets/img-sspunk/stars-btn-light.png"
import traderic1 from "../assets/images/icon/aboutus_card_icon1.png"
import traderic2 from "../assets/images/icon/aboutus_card_icon2.png"
import traderic3 from "../assets/images/icon/aboutus_card_icon3.png"
import traderic4 from "../assets/images/icon/aboutus_card_icon4.png"
import traderic5 from "../assets/images/icon/aboutus_card_icon5.png"
import traderic6 from "../assets/images/icon/aboutus_card_icon6.png"
import cat1 from "../assets/img-sspunk/nft-fl-1.jpg"
import cat2 from "../assets/img-sspunk/nft-fl-2.jpg"
import cat3 from "../assets/img-sspunk/nft-fl-3.jpg"
import cat4 from "../assets/img-sspunk/nft-fl-4.jpg"
import cat5 from "../assets/img-sspunk/nft-fl-5.jpg"
import cat6 from "../assets/img-sspunk/nft-fl-6.jpg"
import cat7 from "../assets/img-sspunk/nft-fl-7.jpg"
import cat8 from "../assets/img-sspunk/nft-fl-8.jpg"
import cat9 from "../assets/img-sspunk/nft-fl-9.jpg"
import cat10 from "../assets/img-sspunk/nft-fl-10.jpg"
import cat11 from "../assets/img-sspunk/nft-fl-11.jpg"
import cat12 from "../assets/img-sspunk/nft-fl-12.jpg"
import cat13 from "../assets/img-sspunk/nft-fl-13.jpg"
import cat14 from "../assets/img-sspunk/nft-fl-14.jpg"
import cat15 from "../assets/img-sspunk/nft-fl-15.jpg"
import cat16 from "../assets/img-sspunk/nft-fl-16.jpg"
import cat17 from "../assets/img-sspunk/nft-fl-17.jpg"
import cat18 from "../assets/img-sspunk/nft-fl-18.jpg"
import cat19 from "../assets/img-sspunk/nft-fl-19.jpg"
import cat20 from "../assets/img-sspunk/nft-fl-20.jpg"
import cat21 from "../assets/img-sspunk/nft-fl-21.jpg"
import cat22 from "../assets/img-sspunk/nft-fl-22.jpg"
import cat23 from "../assets/img-sspunk/nft-fl-23.jpg"
import cat24 from "../assets/img-sspunk/nft-fl-24.jpg"
import rd1 from "../assets/img-sspunk/nft-roadmap-1.jpg"
import rd2 from "../assets/img-sspunk/nft-roadmap-2.jpg"
import rd3 from "../assets/img-sspunk/nft-roadmap-3.jpg"
import rd4 from "../assets/img-sspunk/nft-roadmap-4.jpg"
import divider from "../assets/images/nft/v4_roadmap_divider.png"
import crew1 from "../assets/images/team/v4_crew1.png"
import roadmapblur from "../assets/img-sspunk/roadmap-blur.png"
import audit from "../assets/img-sspunk/audit-report.png"
import contract from "../assets/img-sspunk/contract-address.png"
import plus from "../assets/images/icon/plus.svg"
import minus from "../assets/images/icon/munis.svg"
import st1 from "../assets/images/icon/star_1.svg"
import st2 from "../assets/images/icon/star_2.svg"
import st3 from "../assets/images/icon/star_3.svg"
import st4 from "../assets/images/icon/star_4.svg"
import st5 from "../assets/images/icon/star_5.svg"
import st6 from "../assets/images/icon/star_7.svg"
import metamask from "../assets/images/icon/MetaMask.svg"
import coinbase from "../assets/images/icon/Formatic.svg"
import abt from "../assets/img-sspunk/about.png"
import fe1 from "../assets/img-sspunk/fe-1.png"
import fe2 from "../assets/img-sspunk/fe-2.png"
import fe3 from "../assets/img-sspunk/fe-3.png"
import fe4 from "../assets/img-sspunk/fe-4.png"
import fe5 from "../assets/img-sspunk/fe-5.png"
import util from "../assets/img-sspunk/utilities.png"
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import trustwallet from "../assets/images/icon/Trust_Wallet.svg"
import walletconnect from "../assets/images/icon/WalletConnect.svg"
import traits from "../assets/img-sspunk/arrow-traits.png"
import sslp from "../assets/img-sspunk/ss-lp.gif"
import nfthidden from "../assets/img-sspunk/nft-mbox-13.jpg"
import ig1 from "../assets/images/icon/aboutus_card_icon1.png"
import ig2 from "../assets/images/icon/aboutus_card_icon2.png"
import ig3 from "../assets/images/icon/aboutus_card_icon3.png"
import ig4 from "../assets/images/icon/aboutus_card_icon4.png"
import bgtr from "../assets/images/bg/bithu_roadmap_mash_Grad.png"
import { gql } from '@apollo/client';
import { apiURI } from "../config";
import { useAddress } from "@thirdweb-dev/react";
// import auditreport from "../" 

// /static/media/bithu_roadmap_mash_Grad.278685b432cad70b1be5.png

// import { Link, animateScroll as scroll } from "react-scroll";






import MintDialog from './MintDialog';
import ConnectWalletButton from './connectWalletButton'




const Page = ({ setpage, page }) => {

    const [slideMint, setSlideMint] = useState(0);
    const [dropFAQ, setdropFAQ] = useState("allclosed")
    const [connect, setconnect] = useState(false)
    const [mint, setMint] = useState(false)
    const [minInfo, setminInfo] = useState()
    const [isMobileView, setIsMobileView] = useState(false);
    const address = useAddress();


    useEffect(() => {
        const showMintSlides = () => {
            setSlideMint((prevSlideMint) => (prevSlideMint + 1) % mintSlides.length);
        };

        const intervalId = setInterval(showMintSlides, 1000);

        return () => {
            clearInterval(intervalId);
        };
    }, []);

    useEffect(() => {
        (async () => {
            setpage("home")
            console.log("lc.get", localStorage.getItem('page'))
            const targetElement = document.getElementById(localStorage.getItem('page'));
            if (targetElement) {
                await window.scrollTo({
                    top: targetElement.offsetTop,
                    behavior: "smooth"
                });
                localStorage.setItem('page', 'home')
            }

        })();
    }, []);



    const mintSlides = [
        { imageSrc: nft1, altText: 'Image 1' },
        { imageSrc: nft2, altText: 'Image 2' },
        { imageSrc: nft3, altText: 'Image 3' },
        { imageSrc: nft4, altText: 'Image 4' },
    ];
    const clicky = () => {
        console.log("clicked")
    }




    useEffect(() => {
        const checkMobileView = () => {
            const width = window.innerWidth;
            setIsMobileView(width <= 768); // Set your desired mobile width breakpoint
        };

        // Check initially and add event listener for window resize
        checkMobileView();
        window.addEventListener('resize', checkMobileView);

        // Clean up the event listener on component unmount
        return () => {
            window.removeEventListener('resize', checkMobileView);
        };
    }, []);

    const mintNow = () => {

        if (address === undefined) {
            alert("Please connect your wallet to mint")
        }
        else {
            let walletAddress = address.toLowerCase();
            getMintInfo(walletAddress);
            setMint(true);
        }
        //check if wallet is connected using cookies or local storage and call getMintInfo.

    }



    const getMintInfo = async (walletAddress) => {
        if (walletAddress === undefined) {
            alert("Please connect your wallet to mint")
        }
        else {




            console.log("inside get");
            try {
                var query = `
          query ExampleQuery($walletAddress: String) {
            id:
            getMintInfo(walletAddress: $walletAddress) {
                max_quantity
                remaining
            }
          }
                        `;
                fetch(apiURI.URL, {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json",
                        Accept: "application/json",
                        "x-domain-agent": "superstarpunks.com"
                    },
                    body: JSON.stringify({
                        query,
                        variables: {
                            "walletAddress": walletAddress
                        },
                    }),
                })
                    .then((response) => {
                        const json = response.json();

                        return json;
                    })
                    .then(async (data) => {
                        setminInfo(data?.data?.id)
                        console.log(data);
                    });
            } catch (error) {

            }
        }
    };


    const getNFT = async () => {

        console.log("inside get");
        // try {
        //     var query = `
        //         query GetNFTsByWallet($walletAddress: String) {
        //             getNFTsByWallet(walletAddress: $walletAddress) {
        //               walletAddress
        //               nft_id
        //               traits {
        //                 trait_type
        //                 value
        //               }
        //               imageUrl
        //             }
        //           }

        //                     `;
        //     fetch(apiURI.URL, {
        //         method: "POST",
        //         headers: {
        //             "Content-Type": "application/json",
        //             Accept: "application/json",
        //         },
        //         body: JSON.stringify({
        //             query,
        //             variables: {
        //                 "walletAddress": walletAddress
        //             },
        //         }),
        //     })
        //         .then((response) => {
        //             const json = response.json();

        //             return json;
        //         })
        //         .then(async (data) => {
        //             // setnftDetails(data)
        //             console.log(data, "nft collections");
        //         });
        // } catch (error) {

        // }

    };


    return (
        <>







            <div >
                <section className="loader_first" style={{ display: "none" }}>
                    <div className="loader_first_inner">
                        <div className="circular-spinner"></div>
                        <h3 style={{ marginTop: "200px" }}>Minting..</h3>
                    </div>
                </section>



                <Navbar connect={connect} setMint={setMint} page={page} mintNow={mintNow}></Navbar>
                <MintDialog mint={mint} setMint={setMint} setminInfo={setminInfo} minInfo={minInfo} getNFT={getNFT} />


                <div className="bithu_v4_baner_sect" id="home">
                    <canvas id="canvas" className="v4firefly_bg"></canvas>
                    <div className="container">
                        <div className="row flex-row-reverse">
                            <div className="col-lg-6">
                                <div className="bithu_v1_baner_right bithu_v4_baner_right">
                                    <span className="star1">
                                        <img src={star} alt="star" ></img></span>
                                    <span className="star2">
                                        <img src={star2} alt="star" ></img></span>
                                    <span className="star3"><img src={star} alt="star" /></span>
                                    <div className="bithu_v4_baner_right_content">
                                        <div className="v4_baner_img_card v4_baner_img_card1">
                                            <div className="v4_baner_img v4_baner_img1">
                                                <img src={sslp} alt="img" />
                                            </div>
                                            <div className="v4_baner_img_shapes">
                                                <div className="v4_baner_img_shapes_left">
                                                    <div className="shape_1"></div>
                                                    <div className="shape_2"></div>
                                                </div>
                                                <div className="v4_baner_img_shapes_right">
                                                    <div className="shape_3"></div>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="v4_baner_img_card v4_baner_img_card2">

                                            <div className="v4_baner_img v4_baner_img2">
                                                <div className="mint-slideshow-container">
                                                    {mintSlides.map((slide, index) => (
                                                        <div
                                                            key={index}
                                                            className={`mintSlides`}
                                                            style={{ display: index === slideMint ? "block" : "none" }}
                                                        >
                                                            <img src={slide.imageSrc} alt={slide.altText} className="img-fluid" />
                                                        </div>
                                                    ))}
                                                </div>
                                                {/* <div className="mint-slideshow-container">

                                            <div className="mintSlides">
                                                <img src={nft1} alt="img" className="img-fluid" />
                                            </div>

                                            <div className="mintSlides">
                                                <img src={nft2} alt="img" className="img-fluid" />
                                            </div>

                                            <div className="mintSlides">
                                                <img src={nft3} alt="img" className="img-fluid" />
                                            </div>

                                            <div className="mintSlides">
                                                <img src={nft4} alt="img" className="img-fluid" />
                                            </div>

                                        </div> */}
                                                <br></br>

                                                <div style={{ display: "none" }}>
                                                    <span className="dotmint"></span>
                                                    <span className="dotmint"></span>
                                                    <span className="dotmint"></span>
                                                    <span className="dotmint"></span>
                                                </div>
                                            </div>
                                            <div className="v4_baner_img_shapes">
                                                <div className="v4_baner_img_shapes_left">
                                                    <div className="shape_1"></div>
                                                    <div className="shape_2"></div>
                                                </div>
                                                <div className="v4_baner_img_shapes_right">
                                                    <div className="shape_3"></div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="bithu_v4_baner_right_overlay">
                                            <span><img src={blur} alt="img" /></span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-6">
                                
                                <div className="bithu_v1_baner_left bithu_v4_baner_left">
                                    <h2 className="ft-head">Discover a World of Iconic Legends <br></br>as Digital Collectibles </h2>

                                    <div className="bithu_v1_baner_buttons mt-0">
                                        <button className="mint_btn hov_shape_show cus-btn cs" onClick={() => { mintNow() }} style={{ zIndex: "1" }}>
                                            Mint now
                                            <span className="hov_shape1"><img src="assets/img-sspunk/stars-btn-light.png" alt=""></img></span>
                                            <span className="hov_shape2"><img src="assets/img-sspunk/stars-btn-light.png" alt=""></img></span>
                                            <span className="square_hov_shape_dark"></span>
                                        </button>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>

                <div className="bithu_v4owl_carousel_sect">
                    <div className="bithu_v4owl_carousel_content">
                        <ul>
                            <li style={{ marginLeft: "-5px" }}><span><img src={starfloating} alt="icon" /></span>SuperStarPunks</li>
                            <li><span><img src={starfloating} alt="icon" /></span>MINT IS LIVE</li>
                            <li style={{ marginLeft: "-5px" }}><span><img src={starfloating} alt="icon" /></span>SuperStarPunks</li>
                            <li><span><img src={starfloating} alt="icon" /></span>MINT IS LIVE</li>
                            <li style={{ marginLeft: "-5px" }}><span><img src={starfloating} alt="icon" /></span>SuperStarPunks</li>
                            <li><span><img src={starfloating} alt="icon" /></span>MINT IS LIVE</li>
                            <li style={{ marginLeft: "-5px" }}><span><img src={starfloating} alt="icon" /></span>SuperStarPunks</li>
                            <li><span><img src={starfloating} alt="icon" /></span>MINT IS LIVE</li>
                            <li style={{ marginLeft: "-5px" }}><span><img src={starfloating} alt="icon" /></span>SuperStarPunks</li>
                            <li><span><img src={starfloating} alt="icon" /></span>MINT IS LIVE</li>
                            <li style={{ marginLeft: "-5px" }}><span><img src={starfloating} alt="icon" /></span>SuperStarPunks</li>
                            <li><span><img src={starfloating} alt="icon" /></span>MINT IS LIVE</li>
                            <li style={{ marginLeft: "-5px" }}><span><img src={starfloating} alt="icon" /></span>SuperStarPunks</li>
                            <li><span><img src={starfloating} alt="icon" /></span>MINT IS LIVE</li>
                        </ul>
                        <ul>
                            <li style={{ marginLeft: "-5px" }}><span><img src={starfloating} alt="icon" /></span>SuperStarPunks</li>
                            <li><span><img src={starfloating} alt="icon" /></span>MINT IS LIVE</li>
                            <li style={{ marginLeft: "-5px" }}><span><img src={starfloating} alt="icon" /></span>SuperStarPunks</li>
                            <li><span><img src={starfloating} alt="icon" /></span>MINT IS LIVE</li>
                            <li style={{ marginLeft: "-5px" }}><span><img src={starfloating} alt="icon" /></span>SuperStarPunks</li>
                            <li><span><img src={starfloating} alt="icon" /></span>MINT IS LIVE</li>
                            <li style={{ marginLeft: "-5px" }}><span><img src={starfloating} alt="icon" /></span>SuperStarPunks</li>
                            <li><span><img src={starfloating} alt="icon" /></span>MINT IS LIVE</li>
                            <li style={{ marginLeft: "-5px" }}><span><img src={starfloating} alt="icon" /></span>SuperStarPunks</li>
                            <li><span><img src={starfloating} alt="icon" /></span>MINT IS LIVE</li>
                            <li style={{ marginLeft: "-5px" }}><span><img src={starfloating} alt="icon" /></span>SuperStarPunks</li>
                            <li><span><img src={starfloating} alt="icon" /></span>MINT IS LIVE</li>
                            <li style={{ marginLeft: "-5px" }}><span><img src={starfloating} alt="icon" /></span>SuperStarPunks</li>
                            <li><span><img src={starfloating} alt="icon" /></span>MINT IS LIVE</li>
                        </ul>
                    </div>
                </div>

                <div className="v4_about_us_sect" id="about" style={{minHeight:"auto"}}>
                    <div className="container">
                        <div  className="audit_contract">
                        <a href="https://ipfs.io/ipfs/QmNgNaLwzgMxcx9r6qDvJmTFam6xxUxX7Vp8E99oRt7i74/" target="_blank" className="connect_btn hov_shape_show cus-btn ml-5 mr-5 mt-3" style={{fontSize:"16px",padding:"10px 25px",fontFamily:'Bakbak One',borderRadius:"50px"}} ><span><img style={{width:"50px"}} src={audit}></img></span>&nbsp;AUDIT REPORT</a>
                        <a href="https://ozonescan.io/address/0x06ae945f7A49F98979184e8194080F5CB37acD12" target="_blank" className="connect_btn hov_shape_show cus-btn ml-5 mr-5 mt-3" style={{fontSize:"16px",padding:"10px 25px",fontFamily:'Bakbak One',borderRadius:"50px"}} ><span><img style={{width:"50px"}} src={contract}></img></span> &nbsp;CONTRACT ADDRESS</a>
                        </div>

                    </div>
                </div>
                <div className="v4_about_us_sect" id="about">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-6">
                                <div className="img-about">
                                    <img src={abt}></img>
                                </div>

                            </div>
                            <div className="col-md-6">
                                <div className="v4_about_us_right">
                                    <div className="bithu_title_section">
                                        <h2 className="st-line">The Story <img src="assets/img-sspunk/stars-btn-light.png" alt="" /></h2>
                                        <h3>About Us</h3>
                                    </div>
                                    <div className="v4about_us_text">
                                        <p className="mb-20">SuperStarPunks is an exciting NFT project in Ozone Chain platform that brings together the worlds of blockchain technology and digital collectibles. Our platform offers a ground breaking collection of unique digital characters, each representing a legendary celebrity or iconic figure. Immerse yourself in a universe where your favorite stars come to life in the form of rare and valuable NFTs.</p>

                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>

                <div className="v4_about_us_sect" id="utilities">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-6">
                                <div className="v4_about_us_right">
                                    <div className="bithu_title_section">
                                        {/* <h2 className="st-line">The Story <img src="assets/img-sspunk/stars-btn-light.png" alt="" /></h2> */}
                                        <h3 >Utilities</h3>
                                    </div>
                                    <div className="v4about_us_text mt-2">
                                        <p className="mb-20" style={{fontSize:"18px",border:"solid 1px",borderRadius:"5px",padding:"8px 12px",borderWidth:"4px",borderImage:"linear-gradient(to right, rgba(255,124,7,1), rgba(205,6,232,1),rgba(205,6,232,1) 62%,rgba(27,69,252,1) 100%) 1"}}>Receive 1250 OZO coins for 1 NFT Mint</p>
                                        <p className="mb-20" style={{fontSize:"18px",border:"solid 1px",borderRadius:"5px",padding:"8px 12px",borderWidth:"4px",borderImage:"linear-gradient(to right, rgba(255,124,7,1), rgba(205,6,232,1),rgba(205,6,232,1) 62%,rgba(27,69,252,1) 100%) 1"}}>Eligibility for BabyOZO Airdrop.</p>
                                        <p className="mb-20" style={{fontSize:"18px",border:"solid 1px",borderRadius:"5px",padding:"8px 12px",borderWidth:"4px",borderImage:"linear-gradient(to right, rgba(255,124,7,1), rgba(205,6,232,1),rgba(205,6,232,1) 62%,rgba(27,69,252,1) 100%) 1"}}>Free upgrade to limited edition of Super-Duper Punks.</p>
                                        <p className="mb-20" style={{fontSize:"18px",border:"solid 1px",borderRadius:"5px",padding:"8px 12px",borderWidth:"4px",borderImage:"linear-gradient(to right, rgba(255,124,7,1), rgba(205,6,232,1),rgba(205,6,232,1) 62%,rgba(27,69,252,1) 100%) 1"}}>Get free merchandise (T-shirts & Accessories).</p>



                                    </div>
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="img-about utilities" >
                                    <img src={util} style={{ width: "400px" }}></img>
                                </div>

                            </div>


                        </div>
                    </div>
                </div>


                <div className="triader_benefits_sect" id="features">
                    <div className="container">
                        <div className="bithu_title_section text-center">

                            <h3>Features</h3>
                            <p>SuperStarPunks offers a range of exciting features that enhance the experience of owning and collecting our unique NFTs. These features include:</p>
                        </div>
                        <div className="about_us_text_card_sect triader_benefits_card">
                            <div className="row">
                                <div className="col-md-4 mb-30">
                                    <div className="about_us_text_card text-center">
                                        <div className="img-sspunk-fe mb-20"><img className="img-fe" src={fe3} alt="icon" /></div>
                                        <h3>Limited Supply and Rarity</h3>
                                        <p>SuperStarPunks maintains a strictly limited supply of 500 NFTs, ensuring their rarity and exclusivity. Each SuperStarPunks NFT is a unique piece of digital art that cannot be replicated, making it a valuable collector's item.</p>
                                    </div>
                                </div>
                                <div className="col-md-4 mb-30">
                                    <div className="about_us_text_card text-center">
                                        <div className="img-sspunk-fe mb-20"><img className="img-fe" src={fe1} alt="icon" /></div>
                                        <h3>Unique Traits and Attributes</h3>
                                        <p>Every SuperStarPunks NFT possesses a distinct combination of traits, attributes, accessories, backgrounds, and special characteristics. These traits are algorithmically generated, resulting in a diverse collection of NFTs where no two are exactly alike.</p>
                                    </div>
                                </div>
                                <div className="col-md-4 mb-30">
                                    <div className="about_us_text_card text-center">
                                        <div className="img-sspunk-fe mb-20"><img className="img-fe" src={fe4} alt="icon" /></div>
                                        <h3>Scalable Governance Framework</h3>
                                        <p>SuperStarPunks empowers token holders by giving them a voice in the project's governance. Holders can participate in decision-making processes, community initiatives, and voting on proposals that shape the future direction of SuperStarPunks.</p>
                                    </div>
                                </div>
                                <div className="col-md-4 offset-lg-2 mb-30">
                                    <div className="about_us_text_card text-center">
                                        <div className="img-sspunk-fe mb-20"><img className="img-fe" src={fe2} alt="icon" /></div>
                                        <h3>Access to Exclusive Benefits</h3>
                                        <p>Owning a SuperStarPunks NFT grants access to a range of exclusive benefits and experiences. Holders gain entry to member-only events, VIP experiences, early access to new releases, and other exciting perks within the SuperStarPunks ecosystem.</p>
                                    </div>
                                </div>
                                <div className="col-md-4 mb-30">
                                    <div className="about_us_text_card text-center">
                                        <div className="img-sspunk-fe mb-20"><img className="img-fe" src={fe5} alt="icon" /></div>
                                        <h3>Community Engagement</h3>
                                        <p>SuperStarPunks fosters a vibrant and inclusive community where collectors and enthusiasts can interact, collaborate, and contribute. Through social channels, forums, and interactive events, members can connect with like-minded individuals, share their passion for SuperStarPunks, and participate in community-driven initiatives.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>




                <div className="cat_imgs_sect">
                    <div className="cat_imgs_row1">
                        <ul>
                            <li><span><img src={cat1} alt="img" /></span></li>
                            <li><span><img src={cat2} alt="img" /></span></li>
                            <li><span><img src={cat3} alt="img" /></span></li>
                            <li><span><img src={cat4} alt="img" /></span></li>
                            <li><span><img src={cat5} alt="img" /></span></li>
                            <li><span><img src={cat6} alt="img" /></span></li>
                        </ul>
                        <ul>
                            <li><span><img src={cat7} alt="img" /></span></li>
                            <li><span><img src={cat8} alt="img" /></span></li>
                            <li><span><img src={cat9} alt="img" /></span></li>
                            <li><span><img src={cat10} alt="img" /></span></li>
                            <li><span><img src={cat11} alt="img" /></span></li>
                            <li><span><img src={cat12} alt="img" /></span></li>
                        </ul>
                    </div>
                    <div className="cat_imgs_row2">
                        <ul>
                            <li><span><img src={cat13} alt="img" /></span></li>
                            <li><span><img src={cat14} alt="img" /></span></li>
                            <li><span><img src={cat15} alt="img" /></span></li>
                            <li><span><img src={cat16} alt="img" /></span></li>
                            <li><span><img src={cat17} alt="img" /></span></li>
                            <li><span><img src={cat18} alt="img" /></span></li>
                        </ul>
                        <ul>
                            <li><span><img src={cat19} alt="img" /></span></li>
                            <li><span><img src={cat20} alt="img" /></span></li>
                            <li><span><img src={cat21} alt="img" /></span></li>
                            <li><span><img src={cat22} alt="img" /></span></li>
                            <li><span><img src={cat23} alt="img" /></span></li>
                            <li><span><img src={cat24} alt="img" /></span></li>
                        </ul>
                    </div>
                </div>

                <div className="v1_roadmap_sect" id="traits">
                    <div className="container">
                        <div className="bithu_title_section text-center">

                            <h3>Exceptional traits</h3>
                        </div>
                        <div className="v1_roadmap_divider">
                            <img src={traits} alt="" />
                        </div>
                        <div className="v1_roadmap_contents_inner text-center">
                            <div className="v1_roadmap_contents">
                                <div className="swiper slickSlider" >


                                    <div className="swiper-wrapper">
                                        <div className='row'>
                                            <div className='col-xl-4 col-lg-4 col-md-6 col-sm-12 mb-2'>
                                                <div className="swiper-slide v1_roadmap_contents_text pr-0">
                                                    <div className="sl-content mb-4">
                                                        <h3 className='mb-2'>Uniqueness and Scarcity</h3>
                                                        <p className="">At SuperStarPunks, we understand the importance of rarity and exclusivity in the NFT space. That's why our collection consists of a limited supply of meticulously designed digital characters. Each SuperStarPunks NFT is a one-of-a-kind masterpiece, ensuring that you own a truly unique piece of digital art that can never be replicated.</p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='col-xl-4 col-lg-4 col-md-6 col-sm-12 mb-2'>
                                                <div className="swiper-slide v1_roadmap_contents_text pr-0">
                                                    <div className="sl-content mb-4">
                                                        <h3 className='mb-2'>Unlock Exclusive Benefits and Experiences </h3>
                                                        <p className="sl-p">Owning a SuperStarPunks NFT opens up a world of exclusive benefits and experiences. As a proud holder, you gain access to member-only events, VIP experiences, and early access to upcoming releases. Your SuperStarPunks NFT is your key to a community-driven ecosystem where you have a voice in the decision-making process through our governance framework.</p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='col-xl-4 col-lg-4 col-md-6 col-sm-12 mb-2'>
                                                <div className="swiper-slide v1_roadmap_contents_text pr-0">
                                                    <div className="sl-content mb-4">
                                                        <h3 className='mb-2'>Engage with a Passionate Community</h3>
                                                        <p className="sl-p">SuperStarPunks is more than just a collection of digital collectibles; it's a thriving community of like-minded individuals who share a passion for art, technology, and pop culture. Join our vibrant community and connect with fellow collectors and enthusiasts through our social channels, forums, and interactive events. Collaborate, trade, and grow together as we shape the future of SuperStarPunks.</p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='col-xl-4 col-lg-4 col-md-6 col-sm-12 mb-2'>   <div className="swiper-slide v1_roadmap_contents_text pr-0">
                                                <div className="sl-content mb-4">
                                                    <h3 className='mb-2'>Strategic Partnerships and Collaborations</h3>
                                                    <p className="sl-p">We believe in the power of partnerships to amplify our reach and create unique experiences for our community. SuperStarPunks actively seeks collaborations with established brands, celebrities, and influencers. By forging alliances, we aim to bring more exciting characters to our collection and unlock opportunities for special events and engagements. </p>
                                                </div>
                                            </div></div>
                                            <div className='col-xl-4 col-lg-4 col-md-6 col-sm-12 mb-2'>        <div className="swiper-slide v1_roadmap_contents_text pr-0">
                                                <div className="sl-content mb-4">
                                                    <h3 className='mb-2'>Roadmap and Future Developments </h3>
                                                    <p className="sl-p">Our roadmap outlines our vision and future plans for SuperStarPunks. We have an exciting line-up of additional collections, utility-driven features, collaborations, and expansion into new platforms and ecosystems. Stay tuned as we continue to innovate and bring you more unforgettable moments in the world of SuperStarPunks.</p>
                                                </div>
                                            </div></div>
                                            <div className='col-xl-4 col-lg-4 col-md-6 col-sm-12 mb-2'>    <div className="swiper-slide v1_roadmap_contents_text pr-0">
                                                <div className="sl-content mb-4">
                                                    <h3 className='mb-2'>Get Started with SuperStarPunks</h3>
                                                    <p className="sl-p">Join the revolution and become part of the SuperStarPunks community today. Explore our collection, discover your favorite legends, and secure your own rare NFTs. Embrace the future of digital collectibles with SuperStarPunks, where iconic legends live on in the blockchain.</p>
                                                </div>
                                            </div></div>

                                        </div>
                                        {/* 
                                        <Swiper
                                            spaceBetween={50}
                                            slidesPerView={isMobileView?1:4}
                                            onSlideChange={() => console.log('slide change')}
                                            onSwiper={(swiper) => console.log(swiper)}
                                        >
                                            <SwiperSlide >    
                                                <div className="swiper-slide v1_roadmap_contents_text">
                                                <div className="sl-content mb-4">
                                                    <h3>Uniqueness and Scarcity</h3>
                                                    <p className="sl-p">At SuperStarPunks, we understand the importance of rarity and exclusivity in the NFT space. That's why our collection consists of a limited supply of meticulously designed digital characters. Each SuperStarPunks NFT is a one-of-a-kind masterpiece, ensuring that you own a truly unique piece of digital art that can never be replicated.</p>
                                                </div>
                                            </div></SwiperSlide>
                                            <SwiperSlide>   <div className="swiper-slide v1_roadmap_contents_text">
                                                <div className="sl-content mb-4">
                                                    <h3>Unlock Exclusive Benefits and Experiences </h3>
                                                    <p className="sl-p">Owning a SuperStarPunks NFT opens up a world of exclusive benefits and experiences. As a proud holder, you gain access to member-only events, VIP experiences, and early access to upcoming releases. Your SuperStarPunks NFT is your key to a community-driven ecosystem where you have a voice in the decision-making process through our governance framework.</p>
                                                </div>
                                            </div></SwiperSlide>
                                            <SwiperSlide>  <div className="swiper-slide v1_roadmap_contents_text">
                                                <div className="sl-content mb-4">
                                                    <h3>Engage with a Passionate Community</h3>
                                                    <p className="sl-p">SuperStarPunks is more than just a collection of digital collectibles; it's a thriving community of like-minded individuals who share a passion for art, technology, and pop culture. Join our vibrant community and connect with fellow collectors and enthusiasts through our social channels, forums, and interactive events. Collaborate, trade, and grow together as we shape the future of SuperStarPunks.</p>
                                                </div>
                                            </div></SwiperSlide>
                                            <SwiperSlide> <div className="swiper-slide v1_roadmap_contents_text">
                                                <div className="sl-content mb-4">
                                                    <h3>Strategic Partnerships and Collaborations</h3>
                                                    <p className="sl-p">We believe in the power of partnerships to amplify our reach and create unique experiences for our community. SuperStarPunks actively seeks collaborations with established brands, celebrities, and influencers. By forging alliances, we aim to bring more exciting characters to our collection and unlock opportunities for special events and engagements. </p>
                                                </div>
                                            </div></SwiperSlide>
                                            <SwiperSlide>
                                                <div className="swiper-slide v1_roadmap_contents_text">
                                                    <div className="sl-content mb-4">
                                                        <h3>Strategic Partnerships and Collaborations</h3>
                                                        <p className="sl-p">We believe in the power of partnerships to amplify our reach and create unique experiences for our community. SuperStarPunks actively seeks collaborations with established brands, celebrities, and influencers. By forging alliances, we aim to bring more exciting characters to our collection and unlock opportunities for special events and engagements. </p>
                                                    </div>
                                                </div></SwiperSlide>
                                                <SwiperSlide>
                                                <div className="swiper-slide v1_roadmap_contents_text">
                                            <div className="sl-content mb-4">
                                                <h3>Roadmap and Future Developments </h3>
                                                <p className="sl-p">Our roadmap outlines our vision and future plans for SuperStarPunks. We have an exciting line-up of additional collections, utility-driven features, collaborations, and expansion into new platforms and ecosystems. Stay tuned as we continue to innovate and bring you more unforgettable moments in the world of SuperStarPunks.</p>
                                            </div>
                                        </div></SwiperSlide>
                                        <SwiperSlide>
                                        <div className="swiper-slide v1_roadmap_contents_text">
                                            <div className="sl-content mb-4">
                                                <h3>Get Started with SuperStarPunks</h3>
                                                <p className="sl-p">Join the revolution and become part of the SuperStarPunks community today. Explore our collection, discover your favorite legends, and secure your own rare NFTs. Embrace the future of digital collectibles with SuperStarPunks, where iconic legends live on in the blockchain.</p>
                                            </div>
                                        </div>
                                        </SwiperSlide>
                       
                                        </Swiper> */}










                                    </div>
                                </div>
                                <div className="sl-arrows">
                                    <div className="swiper-button-next"></div>
                                    <div className="swiper-button-prev"></div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="bithu_roadmap_mass_gard">
                        <img src={bgtr} alt="" />
                    </div>
                </div>






                {/* <div class="bithu_roadmap_section bithu_roadmap_section3 " id="utilities">
            <div class="container">
                <div class="bithu_title_section text-center">
                    <h2><span class="shape_left">
                        <img src="assets/images/icon/title_shapes2.svg" alt=""></img></span><span>
                            <img src="assets/images/icon/title_shapes.svg" alt=""></img></span></h2>
                    <h3>Utilities of SuperStarPunks NFT Holders</h3>
                </div>
                <div class="row v3_roadmap_content">
                    <div class="col-lg-12">
                        <div class="bithu_roadmap_item">
                            <div class="bithu_roadmap_content">
                                <div class="bithu_roadmap_counter">
                                    <div class="roadmap_parcentage"><span>1</span></div>
                                </div>
                                <div class="bithu_roadmap_text">
                                    <div class="roadmap_heading">
                                        <h5 class="mb-15"><a href="#">Will receive equivalent value of OZO coins for 0.2 BNB mint price</a></h5>
                                        <img src="assets/images/blog/arrow.png" alt=""></img>
                                    </div> */}
                {/* <p>Will receive equivalent value of OZO coins for 0.2 BNB mint price</p> */}
                {/* </div>
                            </div>
                            <div class="roadmap_img">
                            <img src={nft1} alt=""></img>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-12">
                        <div class="bithu_roadmap_item">
                            <div class="bithu_roadmap_content">
                                <div class="bithu_roadmap_counter">
                                    <div class="roadmap_parcentage"><span>2</span></div>
                                </div>
                                <div class="bithu_roadmap_text">
                                    <div class="roadmap_heading">
                                        <h5 class="mb-15"><a href="#">Eligibility for BabyOZO Airdrop.</a></h5>
                                        <img src="assets/images/blog/arrow.png" alt=""></img>
                                    </div> */}
                {/* <p>Eligibility for BabyOZO Airdrop.</p> */}
                {/* </div>
                            </div>
                            <div class="roadmap_img">
                            <img src={nft2} alt=""></img>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-12">
                        <div class="bithu_roadmap_item">
                            <div class="bithu_roadmap_content">
                                <div class="bithu_roadmap_counter">
                                    <div class="roadmap_parcentage"><span>3</span></div>
                                </div>
                                <div class="bithu_roadmap_text">
                                    <div class="roadmap_heading">
                                        <h5 class="mb-15"><a href="#">Free upgrade to limited edition of Super-Duper Punks.</a></h5>
                                        <img src="assets/images/blog/arrow.png" alt=""></img>
                                    </div> */}
                {/* <p>Free upgrade to limited edition of Super-Duper Punks.</p> */}
                {/* </div>
                            </div>
                            <div class="roadmap_img">
                            <img src={nft3} alt=""></img>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-12">
                        <div class="bithu_roadmap_item">
                            <div class="bithu_roadmap_content">
                                <div class="bithu_roadmap_counter">
                                    <div class="roadmap_parcentage"><span>4</span></div>
                                </div>
                                <div class="bithu_roadmap_text">
                                    <div class="roadmap_heading">
                                        <h5 class="mb-15"><a href="#">Get free merchandise (T-shirts & Accessaries).</a></h5>
                                        <img src="assets/images/blog/arrow.png" alt=""></img>
                                    </div> */}
                {/* <p>Get free merchandise (T-shirts & Accessaries).</p> */}
                {/* </div>
                            </div>
                            <div class="roadmap_img">
                            <img src={nft4} alt=""></img>
                            </div>
                        </div>
                    </div> */}
                {/* <div class="col-lg-12">
                        <div class="bithu_roadmap_item">
                            <div class="bithu_roadmap_content">
                                <div class="bithu_roadmap_counter">
                                    <div class="roadmap_parcentage"><span>100</span>%</div>
                                </div>
                                <div class="bithu_roadmap_text">
                                    <div class="roadmap_heading">
                                        <h5 class="mb-15"><a href="#">Personalize Your NFT</a></h5>
                                        <img src="assets/images/blog/arrow.png" alt=""></img>
                                    </div>
                                    <p>Sentence structures, to generate Lorem Ipsum which looks reasonable. The generated Lorem Ipsum is therefore always free from repetition, injected humour, or non-characteristic words etc.</p>
                                </div>
                            </div>
                            <div class="roadmap_img">
                                <img src="assets/images/blog/roadmap-img5.png" alt=""></img>
                            </div>
                        </div>
                    </div> */}
                {/* </div>
            </div>
        </div> */}





                {/* <div className="bithu_team_sect v4_team_sect" id="team">
                    <div className="v4_team_overlay">
                        <div className="container">
                            <div className="bithu_title_section text-center">
                                <h2><span className="shape_left"><img src={mulstar} alt="" /></span>TEAM MEMBERS<span><img src={mulstar} alt="" /></span></h2>
                                <h3>Meet The Crew</h3>
                            </div>
                            <div className="bithu_team_content">
                                <div className="row">
                                    <div className="col-lg-3 col-md-4 col-sm-6">
                                        <div className="bithu_team_card hov_shape_team">
                                            <div className="team_member_img">
                                                <img src={crew1} alt="" />
                                            </div>
                                            <h3>Berneice Tran</h3>
                                            <h4>Founder</h4>
                                            <span className="hov_shape1"><img src="assets/images/icon/hov_shape_L.svg" alt="" /></span>
                                            <span className="hov_shape2"><img src="assets/images/icon/hov_shape_L.svg" alt="" /></span>
                                            <div className="team_social_icon">
                                                <ul>
                                                    <li><a href="#"><i className="fa-brands fa-twitter"></i></a></li>
                                                    <li><a href="#"><i className="fa-brands fa-instagram"></i></a></li>
                                                    <li><a href="#"><i className="fa-brands fa-linkedin-in"></i></a></li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-3 col-md-4 col-sm-6">
                                        <div className="bithu_team_card hov_shape_team">
                                            <div className="team_member_img">
                                                <img src={crew1} alt="" />
                                            </div>
                                            <h3>Nathan Bean</h3>
                                            <h4>Co-Founder</h4>
                                            <span className="hov_shape1"><img src="assets/images/icon/hov_shape_L.svg" alt="" /></span>
                                            <span className="hov_shape2"><img src="assets/images/icon/hov_shape_L.svg" alt="" /></span>
                                            <div className="team_social_icon">
                                                <ul>
                                                    <li><a href="#"><i className="fa-brands fa-twitter"></i></a></li>
                                                    <li><a href="#"><i className="fa-brands fa-instagram"></i></a></li>
                                                    <li><a href="#"><i className="fa-brands fa-linkedin-in"></i></a></li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-3 col-md-4 col-sm-6">
                                        <div className="bithu_team_card hov_shape_team">
                                            <div className="team_member_img">
                                                <img src={crew1} alt="" />
                                            </div>
                                            <h3>Lynnette 4Wilkes</h3>
                                            <h4>Aritst</h4>
                                            <span className="hov_shape1"><img src="assets/images/icon/hov_shape_L.svg" alt="" /></span>
                                            <span className="hov_shape2"><img src="assets/images/icon/hov_shape_L.svg" alt="" /></span>
                                            <div className="team_social_icon">
                                                <ul>
                                                    <li><a href="#"><i className="fa-brands fa-twitter"></i></a></li>
                                                    <li><a href="#"><i className="fa-brands fa-instagram"></i></a></li>
                                                    <li><a href="#"><i className="fa-brands fa-linkedin-in"></i></a></li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-3 col-md-4 col-sm-6">
                                        <div className="bithu_team_card hov_shape_team">
                                            <div className="team_member_img">
                                                <img src={crew1} alt="" />
                                            </div>
                                            <h3>Claudia Hicks</h3>
                                            <h4>Co-Artist</h4>
                                            <span className="hov_shape1"><img src="assets/images/icon/hov_shape_L.svg" alt="" /></span>
                                            <span className="hov_shape2"><img src="assets/images/icon/hov_shape_L.svg" alt="" /></span>
                                            <div className="team_social_icon">
                                                <ul>
                                                    <li><a href="#"><i className="fa-brands fa-twitter"></i></a></li>
                                                    <li><a href="#"><i className="fa-brands fa-instagram"></i></a></li>
                                                    <li><a href="#"><i className="fa-brands fa-linkedin-in"></i></a></li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div> */}
                {/* 
                <div className="bithu_faq_sect" id="faq">
                    <div className="container">
                        <div className="bithu_faq_content">
                            <div className="bithu_title_section text-center">
                                <h2><span className="shape_left"><img src={mulstar} alt="" /></span>Questions & Answers<span><img src={mulstar} alt="" /></span></h2>
                                <h3>Frequently Asked <br></br> Questions</h3>
                            </div>
                            <div className="bithu_faq_questions">
                                <div className="faq_questions">
                                    <h2 className={dropFAQ == "1" ? "accordion no-border active" : "accordion"}>
                                        How many SuperStarPunks are there?
                                        <span>
                                            <img src={plus} alt="" className="img-fluid IconPlus" onClick={() => setdropFAQ(`1`)}></img>
                                            <img src={minus} alt="" className="Img-fluid IconMunise" onClick={() => setdropFAQ(`allclosed`)}></img>
                                        </span>
                                    </h2>
                                    <div className="accordion-panel" style={{ maxHeight: dropFAQ == "1" ? "108px" : "0px" }}>
                                        <p>We've got a limited collection of 500 SuperStarPunks ready to take the world by storm! Each one is a unique masterpiece that will make you feel like a certified digital superstar.</p>
                                    </div>

                                    <h2 className={dropFAQ == "2" ? "accordion no-border active" : "accordion"}>
                                        Can I buy more than one SuperStarPunks?
                                        <span>
                                            <img src={plus} alt="" className="img-fluid IconPlus" onClick={() => setdropFAQ(`2`)}></img>
                                            <img src={minus} alt="" className="Img-fluid IconMunise" onClick={() => setdropFAQ(`allclosed`)}></img>
                                        </span>
                                    </h2>
                                    <div className="accordion-panel" style={{ maxHeight: dropFAQ == "2" ? "108px" : "0px" }}>
                                        <p>Absolutely! You can become the proud owner of multiple SuperStarPunks and build your very own entourage of digital fame. But remember, sharing the stardom is caring!</p>
                                    </div>

                                    <h2 className={dropFAQ == "3" ? "accordion no-border active" : "accordion"}>
                                        What's the difference between a SuperStarPunks and a regular punk?
                                        <span>
                                            <img src={plus} alt="" className="img-fluid IconPlus" onClick={() => setdropFAQ(`3`)}></img>
                                            <img src={minus} alt="" className="Img-fluid IconMunise" onClick={() => setdropFAQ(`allclosed`)}></img>
                                        </span>
                                    </h2>
                                    <div className="accordion-panel" style={{ maxHeight: dropFAQ == "3" ? "108px" : "0px" }}>
                                        <p>Oh, darling, SuperStarPunks are in a league of their own! They're the crème de la crème of digital art, with 50 distinct art pieces for each Superstar. Regular punks might try, but they can't match the star power of our SuperStarPunks.</p>
                                    </div>
                                    <h2 className={dropFAQ == "4" ? "accordion no-border active" : "accordion"}>
                                        Will my SuperStarPunks make me famous?
                                        <span>
                                            <img src={plus} alt="" className="img-fluid IconPlus" onClick={() => setdropFAQ(`4`)}></img>
                                            <img src={minus} alt="" className="Img-fluid IconMunise" onClick={() => setdropFAQ(`allclosed`)}></img>
                                        </span>
                                    </h2>
                                    <div className="accordion-panel" style={{ maxHeight: dropFAQ == "4" ? "108px" : "0px" }}>
                                        <p>Well, darling, owning a SuperStarPunks won't automatically land you on the cover of a gossip magazine, but it will make you feel like a VIP in the digital art world. So, strike a pose and let your SuperStarPunks be your ticket to virtual stardom!</p>
                                    </div>

                                    <h2 className={dropFAQ == "5" ? "accordion no-border active" : "accordion"}>
                                        Can I trade or sell my SuperStarPunks?
                                        <span>
                                            <img src={plus} alt="" className="img-fluid IconPlus" onClick={() => setdropFAQ(`5`)}></img>
                                            <img src={minus} alt="" className="Img-fluid IconMunise" onClick={() => setdropFAQ(`allclosed`)}></img>
                                        </span>
                                    </h2>
                                    <div className="accordion-panel" style={{ maxHeight: dropFAQ == "5" ? "108px" : "0px" }}>
                                        <p>Of course! Our SuperStarPunks marketplace is the place to be for all your buying, selling, and trading needs. Show off your superstar trading skills and connect with other collectors to expand your NFT empire.</p>
                                    </div>

                                    <h2 className={dropFAQ == "6" ? "accordion no-border active" : "accordion"}>
                                        Do SuperStarPunks come with secret powers?
                                        <span>
                                            <img src={plus} alt="" className="img-fluid IconPlus" onClick={() => setdropFAQ(`6`)}></img>
                                            <img src={minus} alt="" className="Img-fluid IconMunise" onClick={() => setdropFAQ(`allclosed`)}></img>
                                        </span>
                                    </h2>
                                    <div className="accordion-panel" style={{ maxHeight: dropFAQ == "6" ? "108px" : "0px" }}>
                                        <p>While our SuperStarPunks won't grant you the ability to fly or read minds, they do possess the power to captivate your imagination and bring a touch of starry magic to your digital world. Prepare to be enchanted!</p>
                                    </div>


                                    <h2 className={dropFAQ == "7" ? "accordion no-border active" : "accordion"}>
                                        Can I customize my SuperStarPunks's appearance?
                                        <span>
                                            <img src={plus} alt="" className="img-fluid IconPlus" onClick={() => setdropFAQ(`7`)}></img>
                                            <img src={minus} alt="" className="Img-fluid IconMunise" onClick={() => setdropFAQ(`allclosed`)}></img>
                                        </span>
                                    </h2>
                                    <div className="accordion-panel" style={{ maxHeight: dropFAQ == "7" ? "108px" : "0px" }}>
                                        <p>Unfortunately, darling, what you see is what you get with our SuperStarPunks. Their unique appearance has been meticulously crafted by our talented artists, so you'll have to embrace their individual charm as it is.</p>
                                    </div>

                                    <h2 className={dropFAQ == "8" ? "accordion no-border active" : "accordion"}>
                                        Are SuperStarPunks suitable for all ages?
                                        <span>
                                            <img src={plus} alt="" className="img-fluid IconPlus" onClick={() => setdropFAQ(`8`)}></img>
                                            <img src={minus} alt="" className="Img-fluid IconMunise" onClick={() => setdropFAQ(`allclosed`)}></img>
                                        </span>
                                    </h2>
                                    <div className="accordion-panel" style={{ maxHeight: dropFAQ == "8" ? "108px" : "0px" }}>
                                        <p>SuperStarPunks are designed for the young at heart, but we recommend that collectors be at least 18 years old to embark on this journey to digital stardom. Let's keep it classy and responsible, folks!</p>
                                    </div>


                                    <h2 className={dropFAQ == "9" ? "accordion no-border active" : "accordion"}>
                                        Will SuperStarPunks bring me good luck?
                                        <span>
                                            <img src={plus} alt="" className="img-fluid IconPlus" onClick={() => setdropFAQ(`9`)}></img>
                                            <img src={minus} alt="" className="Img-fluid IconMunise" onClick={() => setdropFAQ(`allclosed`)}></img>
                                        </span>
                                    </h2>
                                    <div className="accordion-panel" style={{ maxHeight: dropFAQ == "9" ? "108px" : "0px" }}>
                                        <p>We can't promise you good luck, but we can promise you an unforgettable experience and the opportunity to own a piece of digital art history. So, let your SuperStarPunks be your lucky charm in the vast world of NFTs!</p>
                                    </div>

                                    <h2 className={dropFAQ == "10" ? "accordion no-border active" : "accordion"}>
                                        What if I have more questions?
                                        <span>
                                            <img src={plus} alt="" className="img-fluid IconPlus" onClick={() => setdropFAQ(`10`)}></img>
                                            <img src={minus} alt="" className="Img-fluid IconMunise" onClick={() => setdropFAQ(`allclosed`)}></img>
                                        </span>
                                    </h2>
                                    <div className="accordion-panel" style={{ maxHeight: dropFAQ == "10" ? "108px" : "0px" }}>
                                        <p>If you find yourself craving more answers, feel free to reach out to our superstar support team & On Telegram Group .</p>
                                    </div>



                                </div>
                            </div>
                        </div>
                    </div>
                </div> */}

                <div className="bithu_v4_footer_sect pt-30">
                    <div className="container">
                        <div className="bithu_title_section text-center">
                            <h3>Join Our community & <br></br>get Early access <br></br>🤙</h3>
                        </div>
                        <div className="bithu_v1_footer_content">
                            <div className="wh-btns">
                                <button className="mint_btn hov_shape_show cus-btn" style={{ zIndex: "3" }} onClick={() => window.open("https://whitepaper.superstarpunks.com/", "_blank")}>
                                    Whitepaper
                                    <span className="hov_shape1"><img src="assets/img-sspunk/stars-btn-light.png" alt="" /></span>
                                    <span className="hov_shape2"><img src="assets/img-sspunk/stars-btn-light.png" alt="" /></span>
                                    <span className="square_hov_shape_dark"></span>
                                </button></div>

                            {/* <div className="join_comunity_btns">
                        <button className="wishlist_btn hov_shape_show uppercase">
                            Whitelist Now
                            <span className="hov_shape1"><img src="assets/images/icon/hov_shape_L_dark.svg" alt="" /></span>
                            <span className="hov_shape2"><img src="assets/images/icon/hov_shape_L_dark.svg" alt="" /></span>
                            <span className="square_hov_shape_dark"></span>
                        </button>

                        <button className="join_discord_btn hov_shape_show">
                            <img src="assets/images/icon/dis_logo.svg" alt="" />Join Discord
                            <span className="hov_shape1"><img src="assets/images/icon/hov_shape_L.svg" alt="" /></span>
                            <span className="hov_shape2"><img src="assets/images/icon/hov_shape_L.svg" alt="" /></span>
                            <span className="square_hov_shape_dark"></span>
                        </button>
                    </div> */}
                            {/* <div className="join_comunity_btns">
                                <button className="mint_btn hov_shape_show cus-btn">
                                    Whitelist Now
                                    <span className="hov_shape1"><img src="assets/img-sspunk/stars-btn-light.png" alt="" /></span>
                                    <span className="hov_shape2"><img src="assets/img-sspunk/stars-btn-light.png" alt="" /></span>
                                    <span className="square_hov_shape_dark"></span>
                                </button>

                                <button className="join_discord_btn hov_shape_show">
                                    <img src="assets/images/icon/dis_logo.svg" alt="" />Join Discord
                                    <span className="hov_shape1"><img src="assets/images/icon/hov_shape_L.svg" alt="" /></span>
                                    <span className="hov_shape2"><img src="assets/images/icon/hov_shape_L.svg" alt="" /></span>
                                    <span className="square_hov_shape_dark"></span>
                                </button>
                            </div> */}

                            <div className="footer_social_links">
                                <ul>
                                    {/* <li className="social_hov_shape_show">
                                        <a href="#"><span><img src="assets/images/icon/opensea.svg" alt="" /></span></a>
                                        <span className="social_hov_shape1"><img src="assets/images/icon/hov_shape_s.svg" alt="" /></span>
                                    </li>
                                    <li className="social_hov_shape_show">
                                        <a href="#"><i className="fa-brands fa-linkedin-in"></i></a>
                                        <span className="social_hov_shape1"><img src="assets/images/icon/hov_shape_s.svg" alt="" /></span>
                                    </li>
                                    <li className="social_hov_shape_show">
                                        <a href="#"><i className="fa-brands fa-instagram"></i></a>
                                        <span className="social_hov_shape1"><img src="assets/images/icon/hov_shape_s.svg" alt="" /></span>
                                    </li>
                                    <li className="social_hov_shape_show">
                                        <a href="#"><i className="fa-brands fa-twitter"></i></a>
                                        <span className="social_hov_shape1"><img src="assets/images/icon/hov_shape_s.svg" alt="" /></span>
                                    </li>
                                    <li className="social_hov_shape_show">
                                        <a href="#"><i className="fa-brands fa-telegram"></i></a>
                                        <span className="social_hov_shape1"><img src="assets/images/icon/hov_shape_s.svg" alt="" /></span>
                                    </li>
                                    <li className="social_hov_shape_show">
                                        <a href="#"><i className="fa-brands fa-facebook"></i></a>
                                        <span className="social_hov_shape1"><img src="assets/images/icon/hov_shape_s.svg" alt="" /></span>
                                    </li>
                                    <li className="social_hov_shape_show">
                                        <a href="#"><img src="assets/images/icon/med.svg" alt="" /></a>
                                        <span className="social_hov_shape1"><img src="assets/images/icon/hov_shape_s.svg" alt="" /></span>
                                    </li> */}
                                    <li className="social_hov_shape_show">
                                        <a href="https://t.me/superstarpunks" target="_blank"><i className="fa-brands fa-telegram"></i></a>
                                        <span className="social_hov_shape1"><img src="assets/images/icon/hov_shape_s.svg" alt="" /></span>
                                    </li>

                                    <li className="social_hov_shape_show">
                                        <a href="https://twitter.com/SuperStarPunks" target="_blank"><i className="fa-brands fa-twitter"></i></a>
                                        <span className="social_hov_shape1"><img src="assets/images/icon/hov_shape_s.svg" alt="" /></span>
                                    </li>

                                    <li className="social_hov_shape_show">
                                        <a href="https://medium.com/@SuperStarPunks" target="_blank"><i className="fa-brands fa-medium"></i></a>
                                        <span className="social_hov_shape1"><img src="assets/images/icon/hov_shape_s.svg" alt="" /></span>
                                    </li>
                                </ul>
                            </div>

                            <div className="footer_stras_sect">
                                <div className="footer_stars">
                                    <span className="star_1"><img src={st1} alt="" /></span>
                                    <span className="star_2"><img src={st2} alt="" /></span>
                                    <span className="star_3"><img src={st3} alt="" /></span>
                                    <span className="star_4"><img src={st4} alt="" /></span>
                                    <span className="star_5"><img src={st5} alt="" /></span>
                                    <span className="star_6"><img src={st6} alt="" /></span>
                                    <span className="star_7"><img src={st2} alt="" /></span>
                                </div>
                            </div>


                        </div>
                    </div>

                    <Footer></Footer>
                    {/* <div className="connect_modal">
                        <div className={connect?"modal fade show":"modal fade "}  style={{display:"block",zIndex:connect?"2":"0"}}>
                            <div className="modal-dialog modal-dialog-centered" >
                                <div className="modal-content" >
                                    <div className="modal_overlay">
                                        <div className="modal_header">
                                            <h2>Connect Wallet</h2>
                                            <button data-bs-dismiss="modal"  onClick={()=>setconnect(false)} s>
                                                <i className="fa-solid fa-xmark"></i>
                                            </button>
                                        </div>
                                        <div className="modal_body text-center">
                                            <p>Please select a wallet to connect for start Minting your NFTs</p>
                                            <div className="connect-section">
                                                <ul className="heading-list">
                                                    <li><a href="#" className="connect-meta"><span><img src={metamask} alt="Meta-mask-Image"></img></span>MetaMask</a></li>
                                                    <li><a href="#"><span><img src={coinbase} alt="Coinbase-Image"></img></span>Coinbase</a></li>
                                                    <li><a href="#"><span><img src={walletconnect} alt="Wallet-Image"></img></span>WalletConnect</a></li>
                                                </ul>
                                            </div>
                                            <h6>By connecting your wallet, you agree to our <a href="#">Terms of Service</a> and our <a href="#">Privacy Policy</a>.</h6>
                                        </div>
                                        <div className="modal_bottom_shape">
                                            <span className="modal_bottom_shape_left"><img src="assets/images/icon/hov_shape_L.svg" alt="" /></span>
                                            <span className="modal_bottom_shape_right"><img src="assets/images/icon/hov_shape_L.svg" alt="" /></span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div> */}


                    {/* <div className="mint_modal">
                        <div className={mint ? "modal fade show" : "modal fade "} style={{ display: "block", zIndex: mint ? "1" : "0", backgroundColor: "#393b3ca1" }}>
                            <div className="modal-dialog modal-dialog-centered">
                                <div className="modal-content" >
                                    <div className="modal_overlay2">
                                        <div className="modal_header">
                                            <h2>Mint YOUR NFT </h2>
                                            <button onClick={() => setMint(false)}>
                                                <span className="clossbtn_bg"><i className="fa-solid fa-xmark"></i></span>
                                            </button>
                                        </div>
                                        <div className="modal_body text-center">
                                            <div className="mint_img">
                                                <img src={nfthidden} alt="img" style={{ maxWidth: "50%", borderRadius: "25px" }} />
                                            </div>
                                            <div className="mint_count_list">
                                                <ul>
                                                    <li>
                                                        <h5>Remaining</h5>
                                                        <h5>500/<span>500</span></h5>
                                                    </li>
                                                    <li>
                                                        <h5>Price</h5>
                                                        <h5>0.15 ETH</h5>
                                                    </li>
                                                    <li>
                                                        <h5>Quantity</h5>
                                                        <div className="mint_quantity_sect">
                                                            <button onclick="buttonClick_Dec();">-</button>
                                                            <input type="text" id="quantity" value="2"></input>
                                                            <button onclick="buttonClick_Inc();">+</button>
                                                        </div>
                                                        <h5><span>0.30</span> ETH</h5>
                                                    </li>

                                                </ul>
                                            </div>
                                            <button className="modal_mint_btn hov_shape_show">
                                                MINT NOW
                                                <span className="hov_shape1"><img src="assets/images/icon/hov_shape_L_dark.svg" alt="" /></span>
                                                <span className="hov_shape2"><img src="assets/images/icon/hov_shape_L_dark.svg" alt="" /></span>
                                            </button>
                                            {/* <h6>Presale & Whitelist : Soldout</h6> *
                                        </div>
                                        <div className="modal_bottom_shape">
                                            <span className="modal_bottom_shape_left"><img src="assets/images/icon/hov_shape_L.svg" alt="" /></span>
                                            <span className="modal_bottom_shape_right"><img src="assets/images/icon/hov_shape_L.svg" alt="" /></span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div> */}

                    {/* <div className="connect_modal">
                        <div className="modal fade" id="download-metamask" tabindex="-1" aria-hidden="true">
                            <div className="modal-dialog modal-dialog-centered">
                                <div className="modal-content">
                                    <div className="modal_overlay">
                                        <div className="modal_header">
                                            <h2>Connect Wallet</h2>
                                            <button data-bs-dismiss="modal" aria-label="Close">
                                                <i className="fa-solid fa-xmark"></i>
                                            </button>
                                        </div>
                                        <div className="modal_body text-center">
                                            <p>Please download & install metamask extension!</p>
                                            <div className="connect-section">
                                                <ul className="heading-list">
                                                    <li><a href="https://metamask.io/download/" target="_blank" rel='noreferrer'><span>
                                                        <img src="assets/images/icon/MetaMask.svg" alt="Meta-mask-Image"></img></span>MetaMask</a></li>
                                                </ul>
                                            </div>
                                            <h6>By connecting your wallet, you agree to our <a href="#">Terms of Service</a> and our <a href="#">Privacy Policy</a>.</h6>
                                        </div>
                                        <div className="modal_bottom_shape">
                                            <span className="modal_bottom_shape_left"><img src="assets/images/icon/hov_shape_L.svg" alt="" /></span>
                                            <span className="modal_bottom_shape_right"><img src="assets/images/icon/hov_shape_L.svg" alt="" /></span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div> */}

                </div>

            </div>
        </>

    )
}

export default Page
